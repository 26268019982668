function instanaMonitoring(reportingUrl, apiKey) {
    (function (c, e, f, k, g, h, b, a, d) {
        c[g] || (c[g] = h, b = c[h] = function () {
            b.q.push(arguments)
        }, b.q = [], b.l = 1 * new Date, a = e.createElement(f), a.async = 1,
            a.src = k, a.setAttribute("crossorigin", "anonymous"), d = e.getElementsByTagName(f)[0],
            d.parentNode.insertBefore(a, d))
    })(window, document, "script",
        "https://eum.instana.io/eum.min.js", "InstanaEumObject", "ineum");
    ineum('reportingUrl', reportingUrl);
    ineum('apiKey', apiKey);
}
